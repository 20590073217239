import {
  filterByAllowedNames,
  IMetric,
  isToggleActive,
  METRIC_TYPE,
  normalizeBrowser,
  RawRumData,
  readBeaconServicePath,
  RumData,
  sendValidBeacon,
  TOGGLE_SEND_TO_ELK,
} from "./beacon-util";
import { trackingEndpointLogic } from "./tracking";
import { rumArray } from "./filter-list";
import { getPagecluster } from "@otto-ec/global-resources/misc";

const TOGGLE_SEND_RUM_DATA = "bs_send_rum_data";

/**
 *
 *
 *
 *
 */
export function transformForBeaconService(rumDataRaw: RawRumData): RumData {
  try {
    const transformedData: RumData = {
      deviceBrowser: normalizeBrowser(rumDataRaw.deviceBrowser),
      deviceOS: <string>rumDataRaw.deviceOS,
      pageCluster: <string>rumDataRaw.pagecluster,
      rum_deviceType: <string>rumDataRaw.rum_deviceType,
      rum_domComplete: <number>rumDataRaw.rum_domComplete,
      beaconType: "rum",
      rumMetrics: <IMetric[]>(
        (<unknown>filterByAllowedNames(rumDataRaw, rumArray))
      ),
      requestTime: Date.now(),
    };

    if (isToggleActive(TOGGLE_SEND_TO_ELK)) {
      transformedData.url = <string>rumDataRaw.url;
      transformedData.rum_sfid = <string>rumDataRaw.rum_sfid;
    }
    return transformedData;
    /*                                                         */
  } catch (e) {
    /*                    */
  }
}

/**
 *
 *
 */
export function readBeaconServicePathRum(): string {
  return `${readBeaconServicePath()}rum`;
}

/**
 *
 */
const handleRumCollection = () => {
  const navigationTiming = performance.getEntriesByType("navigation")[0];
  const device = window.o_global.device;
  const tsBctElement = window.document.querySelector(".ts-bct");
  const sfid = tsBctElement ? tsBctElement.getAttribute("data-ts_sfid") : "";

  const rawRumData: RawRumData = {
    deviceBrowser: device.browser,
    deviceOS: device.os,
    pagecluster: getPagecluster(),
    rum_deviceType: device.type,
    rum_domComplete: Math.floor(navigationTiming.domComplete),
    rum_sfid: sfid,
    rum_supportsTouch: device.isTouchable,
    url: window.location.href,
  };

  sendValidBeacon(
    transformForBeaconService(rawRumData),
    readBeaconServicePathRum(),
    TOGGLE_SEND_RUM_DATA,
    METRIC_TYPE.rum,
  );
  trackingEndpointLogic(rawRumData);
};

window.addEventListener("load", handleRumCollection, { once: true });
