import * as util from "./beacon-util";
import {
  IMetric,
  isToggleActive,
  METRIC_TYPE,
  normalizeBrowser,
  RawUserTimingData,
  readBeaconServicePath,
  TOGGLE_SEND_TO_ELK,
  UserTimingData,
} from "./beacon-util";
import { trackingEndpointLogic } from "./tracking";
import { userTimingArray } from "./filter-list";

export const toggleNameUserTimings = "bs_send_user_timings";

/**
 *
 */
export function mergeRumData(metricData: RawUserTimingData): UserTimingData {
  const {
    o_global: { device },
    o_util: { misc },
  } = window;
  try {
    const mergedData: UserTimingData = {
      beaconType: "userTiming",
      deviceBrowser: normalizeBrowser(device.browser),
      deviceOS: device.os,
      pageCluster: misc.getPagecluster(),
      requestTime: Date.now(),
      rumMetrics: <IMetric[]>(
        (<unknown>util.filterByAllowedNames(metricData, userTimingArray))
      ),
      rum_deviceType: device.type,
      rum_supportsTouch: device.isTouchable,
    };
    if (isToggleActive(TOGGLE_SEND_TO_ELK)) {
      const tsBctElement = window.document.querySelector(".ts-bct");
      const sfid = tsBctElement
        ? tsBctElement.getAttribute("data-ts_sfid")
        : "";

      mergedData.url = window.location.href;
      mergedData.rum_sfid = sfid;
    }
    return mergedData;
    /*                                                         */
  } catch (e) {
    /*                    */
  }
}

export function readBeaconServicePathUserTiming(): string {
  return readBeaconServicePath() + "userTiming";
}

/**
 */
export function sendUserTimingsToBeacon(metricData: RawUserTimingData) {
  util.sendValidBeacon(
    mergeRumData(metricData),
    readBeaconServicePathUserTiming(),
    toggleNameUserTimings,
    METRIC_TYPE.userTiming,
  );
}

/**
 */
export function sendBeacon(metricData: RawUserTimingData): void {
  sendUserTimingsToBeacon(metricData);
  trackingEndpointLogic(metricData);
}
