import { userTimingArray } from "./filter-list";
import {
  capitalizeFirstLetterAfterPrefix,
  isEmptyBeacon,
  metricPrefix,
  RawRumData,
  RawUserTimingData,
} from "./beacon-util";
import { sendUserTimingsToBeacon } from "./user-timing";

import { LCPMetricWithAttribution, Metric } from "web-vitals/attribution";
import { trackingEndpointLogic } from "./tracking";

const webVitalsToMetricMap = {
  FCP: "firstContentfulPaint",
  LCP: "largestContentfulPaint",
  CLS: "cumulativeLayoutShift",
  INP: "interactionToNextPaint",
};
const lcp_element = "LCPElement";
const lcp_url = "LCPURL";
const lcp_time_to_first_byte = "LCPTimeToFirstByte";
const lcp_resource_load_delay = "LCPResourceLoadDelay";
const lcp_resource_load_duration = "LCPResourceLoadDuration";
const lcp_elementRenderDelay = "LCPElementRenderDelay";

let collectedBeaconServiceMetrics: RawUserTimingData = {};

function isLCPMetricWithAttribution(
  metric: Metric,
): metric is LCPMetricWithAttribution {
  return (
    metric.name === "LCP" &&
    (metric as LCPMetricWithAttribution).attribution !== undefined
  );
}

/**
 *
 *
 */
export function performanceBeaconTracker(metric: Metric) {
  const tracking_metrics: RawRumData = {};
  if (isLCPMetricWithAttribution(metric)) {
    if (metric.attribution.element != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_element}`] =
        metric.attribution.element;
    }
    if (metric.attribution.url != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_url}`] = metric.attribution.url;
    }
    if (metric.attribution.timeToFirstByte != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_time_to_first_byte}`] =
        metric.attribution.timeToFirstByte;
    }
    if (metric.attribution.resourceLoadDelay != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_resource_load_delay}`] =
        metric.attribution.resourceLoadDelay;
    }
    if (metric.attribution.resourceLoadDuration != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_resource_load_duration}`] =
        metric.attribution.resourceLoadDuration;
    }
    if (metric.attribution.elementRenderDelay != undefined) {
      tracking_metrics[`${metricPrefix}${lcp_elementRenderDelay}`] =
        metric.attribution.elementRenderDelay;
    }
  }

  /*                                                                  */
  const metricName = webVitalsToMetricMap[metric.name] || metric.name;
  const duration: number = metric.value;
  const metricNamePascal: string = capitalizeFirstLetterAfterPrefix(metricName);
  const prefixedMetricName = `${metricPrefix}${metricNamePascal}`;

  /*                           */
  if (duration <= 0) {
    return;
  }

  tracking_metrics[prefixedMetricName] = duration;
  trackingEndpointLogic(tracking_metrics);
  /*                               */
  if (userTimingArray.includes(prefixedMetricName)) {
    collectedBeaconServiceMetrics[prefixedMetricName] = duration;
  }
}

/**
 *
 */
export function flushQueue() {
  if (isEmptyBeacon(collectedBeaconServiceMetrics)) {
    return;
  }
  sendUserTimingsToBeacon(collectedBeaconServiceMetrics);
  collectedBeaconServiceMetrics = {};
}

export function isSafari() {
  /*                                                            */
  const userAgent = navigator.userAgent;
  /*                                                                                    */
  /*                                                                                            */
  return (
    userAgent.includes("Safari") &&
    userAgent.includes("AppleWebKit") &&
    !userAgent.includes("Chrome") &&
    !userAgent.includes("CriOS") &&
    !userAgent.includes("Edg")
  );
}
