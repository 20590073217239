const isSupported = "navigator" in window && "sendBeacon" in window.navigator;

let sendBeacon = function (url, data) {
  const xhr =
    "XMLHttpRequest" in window
      ? new XMLHttpRequest()
      : new ActiveXObject("Microsoft.XMLHTTP");
  xhr.open("POST", url, false);
  xhr.withCredentials = true;
  xhr.setRequestHeader("Accept", "application/vnd.otto.de*json");
  if (typeof data === "string") {
    xhr.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    xhr.responseType = "text/plain";
  } else if ({}.toString.call(data) === "[object Blob]") {
    if (data.type) {
      xhr.setRequestHeader("Content-Type", data.type);
    }
  }

  try {
    xhr.send(data);
    /*                                                         */
  } catch (error) {
    /**/
  }
  return true;
};

if (isSupported) {
  sendBeacon = navigator.sendBeacon.bind(navigator);
}

if (!isSupported) {
  window.navigator.sendBeacon = sendBeacon;
}
