import { onCLS, onFCP, onINP, onLCP } from "web-vitals/attribution";
import type { GlobalEvents } from "@otto-ec/global-resources/event-q-bus";

import {
  flushQueue,
  isSafari,
  performanceBeaconTracker,
} from "./quality-vital-metrics";
import { trackingEndpointLogic } from "./tracking";
import { getEffectiveConnectionType } from "./beacon-util";

onLCP(performanceBeaconTracker);
onFCP(performanceBeaconTracker);
onCLS(performanceBeaconTracker);
onINP(performanceBeaconTracker);

/*                                                                             */
if (isSafari()) {
  /*                                                              */
  document.addEventListener("DOMContentLoaded", () => {
    window.o_global
      .events<GlobalEvents>()
      .on("assets.events.allJavascriptLoaded", flushQueue);
  });
  /*                                                             */
  window.addEventListener("pagehide", flushQueue);
  /*                                                                          */
  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushQueue();
    }
  });
} else {
  window.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      flushQueue();
    }
  });
}

const effectiveConnectionType = getEffectiveConnectionType();
if (effectiveConnectionType !== undefined) {
  trackingEndpointLogic({
    rum_effectiveType: effectiveConnectionType,
  });
}
