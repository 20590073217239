import "./polyfill";
import { sendBeacon } from "./user-timing";
import "./rum-metrics";
import "./rum.ts";

declare global {
  interface Window {
    o_scale: {
      userTiming: {
        sendBeacon: typeof sendBeacon;
      };
    };
  }
}

window.o_scale ||= {} as never;
window.o_scale.userTiming = { sendBeacon };
